<template>
  <div class="culture" id="about">
    <div class="_container">
      <div class="culture__container">
        <h3 class="title">{{ title1 }}</h3>
        <div class="culture__text md">
          {{ text1 }}
        </div>
        <div class="culture__text mm text-m">
          <p>{{ text_m1 }}</p>
          <div class="flex">
            <div class="text-l">
              {{ text_m2 }}
            </div>
            <swiper
              :slides-per-view="1"
              :space-between="50"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              :modules="[Autoplay, EffectFade]"
              effect="fade"
              :autoplay="{ delay: 1500 }"
              :loop="true"
              :speed="1"
            >
              <swiper-slide v-for="image in images2" :key="image.id"
                ><img
                  :src="
                    require(`@/assets/img/culture/animation/mobile/${image.img}`)
                  "
                  :alt="image.img"
              /></swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="culture__ornament mm">
          <img src="@/assets/img/svg/ornament.svg" alt="ornament" />
        </div>
        <div class="culture__slider md">
          <div class="culture__arrow arrow-l">
            <img src="@/assets/img/arrow-l.png" alt="arrow" width="100" />
          </div>
          <swiper
            :slides-per-view="1"
            :space-between="50"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :modules="[Autoplay, EffectFade]"
            effect="fade"
            :autoplay="{ delay: 2500 }"
            :loop="true"
            :speed="1"
          >
            <swiper-slide v-for="image in images" :key="image.id">
              <img
                :src="
                  require(`@/assets/img/culture/animation/desktop/${image.img}`)
                "
                :alt="image.img"
            /></swiper-slide>
          </swiper>
          <div class="culture__arrow arrow-r">
            <img src="@/assets/img/arrow-r.png" alt="arrow" width="100" />
          </div>
        </div>
        <h3 class="title">{{ title2 }}</h3>
        <div class="culture__text">
          {{ text2 }}
        </div>
        <div class="culture__text">
          {{ text3 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
export default {
  name: "CultureMain",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      title1: "Национальная кухня",
      title2: "Культура татар",
      text1: `Ресторан TATAR построен на локальном продукте. Из местных продуктов мы делаем\n авторские современные блюда татарской кухни, которые будоражат гастрономическое\n воображение наших гостей. Мы погружаем гостя во вселенную татарской кухни до деталей.\n Нам важно не просто приготовить блюда по вековым рецептам, нам важны и ингредиенты,\n из которых они приготовлены. Поэтому в основе нашего меню только местные продукты,\n всё богатство и щедрость татарстанской земли.`,
      text2: `В нашем меню собраны рецепты многих татарских семей, наших мам и бабушек. Наша\n кухня - это дорогие сердцу воспоминания: родная деревня, треск танцующих искр в печи,\n  ласковые руки бабушки, её убаюкивающие колыбельные.`,
      text3: `Почувствуйте нашу кухню такой, какой мы её запомнили из детства\n и какой мы её видим сейчас.`,
      text_m1: `Ресторан TATAR построен на локальном продукте. Из местных продуктов мы делаем авторские современные блюда татарской кухни, которые будоражат гастрономическое воображение наших гостей. Мы погружаем гостя во вселенную татарской кухни до деталей. Нам важно`,
      text_m2: `не просто приготовить блюда по вековым рецептам, нам важны и ингредиенты, из которых они приготовлены. Поэтому в основе нашего меню только местные продукты, всё богатство и щедрость татарстанской земли.`,
      images: [
        { id: 1, img: "1.png" },
        { id: 2, img: "2.png" },
        { id: 3, img: "3.png" },
        { id: 4, img: "4.png" },
        { id: 5, img: "6.png" },
      ],
      images2: [
        { id: 1, img: "1.png" },
        { id: 2, img: "2.png" },
        { id: 3, img: "3.png" },
        { id: 4, img: "4.png" },
        { id: 5, img: "6.png" },
        { id: 6, img: "7.png" },
      ],
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      Autoplay,
      EffectFade,
    };
  },
};
</script>

<style lang="scss">
.culture {
  background-color: #f6ece9;
  padding: 50px 0 0;
  @media (max-width: $ms3) {
    padding: 30px 0 0;
  }
  .title {
    @media (max-width: $ms3) {
      text-align: left;
    }
  }
  .text-m {
    flex-direction: column;
    position: relative;
    .flex {
      display: flex;
      .text-l {
        min-width: 60%;
        padding-right: 5px;
      }
      .swiper {
        position: relative;
        overflow: hidden;
        margin-right: -25%;
      }
      .swiper-slide {
        width: 100% !important;
        opacity: 0 !important;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .swiper-slide.swiper-slide-active {
        opacity: 1 !important;
      }
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #0d0d0d;
    margin: 20px 0 0 0;
    white-space: pre-line;
    @media (max-width: $ms3) {
      font-size: 14px;
      line-height: 24px;
      text-align: left;
    }
  }

  &__slider {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0 70px 0;
    .arrow-l {
      left: 1rem;
    }
    .arrow-r {
      right: 1rem;
    }
    .swiper {
      width: 400px !important;
      overflow: hidden;
      margin-left: 0;
      margin-right: 0;
      .swiper-slide {
        height: 389.53px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &__arrow {
    position: relative;
  }
  &__ornament {
    filter: invert(0.5);
    padding: 50px 0;
    @media (max-width: $ms3) {
      padding: 35px 0;
    }
  }
}
</style>
