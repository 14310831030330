<template>
  <div class="photos" id="photos">
    <div class="_container">
      <div class="photos__container">
        <div class="photos__blocks md">
          <div
            class="photos__block photo_d"
            v-for="photo in photos"
            :key="photo.id"
            :style="`transform:rotate(${photo.rotate}deg); z-index:${photo.zi}; top:${photo.top}; left:${photo.left};`"
          >
            <img
              :src="require(`@/assets/img/culture/photos/${photo.img}`)"
              :alt="photo.img"
            />
          </div>
        </div>
        <div class="photos__blocks mm">
          <div
            class="photos__block photo_m"
            v-for="photo in photos2"
            :key="photo.id"
            :style="`transform:rotate(${photo.rotate}deg); z-index:${photo.zi}; top:${photo.top}; left:${photo.left}; visibility:${photo.visibility};`"
          >
            <img
              :src="require(`@/assets/img/culture/photos/${photo.img}`)"
              :alt="photo.img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnimPhotos",
  data() {
    return {
      photos: [
        {
          id: 1,
          img: "1.png",
          rotate: 4.77,
          zi: 2,
          top: "0px",
          left: "20px",
        },
        {
          id: 2,
          img: "2.png",
          rotate: -12.87,
          zi: 1,
          top: "0px",
          left: "0px",
        },
        {
          id: 3,
          img: "3.png",
          rotate: 0.11,
          zi: 2,
          top: "0px",
          left: "0px",
        },
        {
          id: 4,
          img: "4.png",
          rotate: 17.3,
          zi: 1,
          top: "-20px",
          left: "-40px",
        },
        {
          id: 5,
          img: "5.png",
          rotate: -6.47,
          zi: 1,
          top: "-30px",
          left: "-75px",
        },
      ],
      photos2: [
        {
          id: 1,
          img: "1.png",
          rotate: -2.23,
          zi: 3,
          top: "-40px",
          left: "80%",
          visibility: "hidden",
        },
        {
          id: 2,
          img: "2.png",
          rotate: -12.87,
          zi: 1,
          top: "0px",
          left: "20%",
          visibility: "visible",
        },
        {
          id: 3,
          img: "3.png",
          rotate: 8.05,
          zi: 2,
          top: "15px",
          left: "3%",
          visibility: "visible",
        },
        {
          id: 4,
          img: "4.png",
          rotate: 17.3,
          zi: 1,
          top: "10px",
          left: "-18%",
          visibility: "visible",
        },
        {
          id: 5,
          img: "5.png",
          rotate: 10.19,
          zi: 3,
          top: "-45px",
          left: "-80%",
          visibility: "hidden",
        },
      ],
    };
  },
  mounted() {
    let photosM = document.querySelectorAll(".photo_m");
    function eachAnimForMobile() {
      setTimeout(() => photosM[0].classList.add("anim1"), 2000);
      setTimeout(() => photosM[4].classList.add("anim2"), 3000);
      setTimeout(() => {
        photosM[4].classList.remove("anim2");
        photosM[0].classList.add("anim3");
      }, 5000);
      setTimeout(() => photosM[0].classList.remove("anim1"), 7000);
    }
    eachAnimForMobile();
    setInterval(() => {
      eachAnimForMobile();
    }, 8000);
    let photosD = document.querySelectorAll(".photo_d");
    function eachAnimForDesktop() {
      setTimeout(() => photosD[1].classList.add("anim"), 1000);
      setTimeout(() => photosD[3].classList.add("anim"), 2000);
      setTimeout(() => photosD[2].classList.add("anim"), 3000);
      setTimeout(() => photosD[0].classList.add("anim"), 4000);
      setTimeout(() => photosD[4].classList.add("anim"), 5000);
      setTimeout(() => photosD[4].classList.remove("anim"), 6000);
      setTimeout(() => photosD[0].classList.remove("anim"), 7000);
      setTimeout(() => photosD[2].classList.remove("anim"), 8000);
      setTimeout(() => photosD[3].classList.remove("anim"), 9000);
      setTimeout(() => photosD[1].classList.remove("anim"), 10000);
    }
    eachAnimForDesktop();
    setInterval(() => {
      eachAnimForDesktop();
    }, 10000);
  },
};
</script>

<style lang="scss" scoped>
.photos {
  background-color: #f6ece9;
  position: relative;
  padding: 100px 0 70px;
  @media (max-width: $ms3) {
    padding: 100px 0 80px;
  }
  &::before {
    content: "";
    position: absolute;
    background-color: #2a374b;
    width: 100%;
    height: 50%;
    bottom: -1px;
  }
  &__blocks {
    padding: 0 50px;
    display: flex;
    justify-content: center;
    @media (max-width: $ms3) {
      padding: 0;
    }
  }
  &__block {
    flex: 0 0 20%;
    position: relative;
    background-color: #fff;
    box-shadow: 3.27px 3.27px 32.67px rgba(0, 0, 0, 0.05);
    padding: 15px 15px 50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: $ms3) {
      flex: 0 0 52%;
      padding: 8px 8px 30px;
    }
  }
}
.anim1 {
  visibility: visible !important;
  animation: anim 0.9s forwards 0.9s;
}
.anim2 {
  visibility: visible !important;
  @keyframes anim {
    0% {
      transform: rotate(-2.23deg);
    }
    100% {
      transform: rotate(0.25deg);
    }
  }
}
.anim3 {
  transform: rotate(-2.23deg) !important;
}
.photo_d {
  visibility: hidden;
  opacity: 0;
}
.anim {
  visibility: visible;
  animation: anim 0.2s forwards 0.2s;
  @keyframes anim {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
