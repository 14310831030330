<template>
  <div class="popupForm">
    <div class="popupForm__container" @click.stop>
      <div class="popupForm__close" @click="$emit('closePopupCall')">
        <img src="@/assets/img/svg/close_popup.svg" alt="close" />
      </div>
      <h1 class="popupForm__title">{{ title }}</h1>
      <form @submit.prevent="validForm" class="form" v-if="isForm">
        <div class="inputs">
          <div class="input-block">
            <label for="name">Имя</label>
            <input
              type="text"
              name="name"
              placeholder="Введите имя"
              id="name"
              v-model.trim="name"
            />
          </div>
          <div class="input-block">
            <label for="tel">Телефон</label>
            <input
              type="text"
              name="tel"
              id="tel"
              placeholder="+7 900 000 00 00"
              v-mask="'+7 ### ### ## ##'"
              v-model.trim="phone"
            />
          </div>
        </div>
        <div class="error">{{ errorText }}</div>
        <div class="form__next">
          <button type="submit" name="send">Отправить</button>
        </div>
      </form>
      <div class="done" v-if="isDone">
        <h2 class="popupForm__title">{{ message }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QrPopup",
  data() {
    return {
      title: "Заказать обратный\n звонок",
      isForm: true,
      isDone: false,
      errorText: "",
      message: "Мы перезвоним вам в\n ближайшее время",
      name: "",
      phone: "",
    };
  },
  methods: {
    validForm() {
      if (this.name === "") {
        this.errorText = "Заполните поле Имя";
      } else if (this.phone === "") {
        this.errorText = "Укажите телефон";
      } else if (this.name.length <= 1) {
        this.errorText = "Слишком короткое имя";
      } else if (this.phone.length <= 10) {
        this.errorText = "Телефон должен состоять из 11 цифр!";
      } else {
        this.phone.length > 15 ? this.phone.length === 15 : 0;
        this.errorText = "";
        this.name = "";
        this.phone = "";
        this.title = "Спасибо!";
        this.isForm = false;
        this.isDone = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/popup-form.scss";
.done {
  margin-top: 2rem;
}
</style>
