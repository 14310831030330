<template>
  <button class="continue-book" @click="$emit('openPopup')">
    <img
      :src="require(`@/assets/img/svg/${img}.svg`)"
      :alt="img"
      :width="width"
    />
    <span>{{ btn }}</span>
  </button>
</template>

<script>
export default {
  name: "ContinueBook",
  data() {
    return {
      btn: "Продолжить бронирование",
      img: "bell",
      width: 16,
    };
  },
};
</script>

<style lang="scss">
.continue-book {
  height: 47px;
  border-radius: 8px;
  position: fixed;
  bottom: 1rem;
  right: 50px;
  z-index: 3;
  padding: 0 1rem;
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
  color: #2a374b;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  @media (max-width: $ms3) {
    right: 1rem;
  }
  &:hover,
  &:focus {
    filter: invert(1);
    transition: 0.2s ease 0.2s;
  }
  span {
    margin-left: 10px;
  }
  &::before {
    content: "";
    position: absolute;
    right: -23px;
    border: 19px solid transparent;
    border-left: 5px solid #fff;
  }
}
</style>
