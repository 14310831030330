<template>
  <section class="banner" id="banner">
    <div class="banner__body">
      <h1 class="banner__title">{{ title }}</h1>
      <div class="banner__ornament">
        <img src="@/assets/img/svg/ornament.svg" alt="ornament" />
      </div>
      <h2 class="banner__subtitle">{{ subtitle }}</h2>
      <div class="banner__period" v-if="!isVideo">{{ period }}</div>
      <div class="banner__buttons">
        <button
          class="banner__btn select"
          @click="$emit('openPopup'), setBlock()"
        >
          <img src="@/assets/img/svg/kitchen.svg" alt="kitchen" />
          <span>Забронировать стол</span>
        </button>
      </div>
    </div>
    <div class="banner__movie">
      <video
        v-if="isVideo"
        width="100%"
        height="100%"
        :poster="require(`@/assets/img/banner/${banner_img}`)"
        loop
        playsinline
        muted
        autoplay
      >
        <source src="#" type="video/mp4" codecs="mp4" />
      </video>
      <img v-if="isImg1" src="@/assets/img/utm/1.jpg" alt="img" />
      <img v-if="isImg2" src="@/assets/img/utm/2.jpg" alt="img" />
      <img v-if="isImg3" src="@/assets/img/utm/3.jpg" alt="img" />
    </div>
    <div class="banner__movie"></div>
  </section>
</template>

<script>
export default {
  name: "BannerMain",
  data() {
    return {
      title: `Душевная\nтатарская кухня`,
      subtitle:
        "Попробуйте щедрость местной земли\n и богатство ее полей, лесов и лугов на вкус\n в ресторане TATAR",
      isVideo: true,
      isImg1: false,
      isImg2: false,
      isImg3: false,
      period: "C 15 мая до 30 июня 2024",
      isPopup: false,
      isPopupFeedback: false,
      banner_img: "banner.png",
    };
  },
  mounted() {
    if (this.$route.query.utm_content === "iftar") {
      this.title =
        "Поздравляем со Священным месяцем Рамадан! Мы рады разделить его вместе с Вами и пригласить на ифтары в самом сердце Казанского Кремля в наш ресторан";
    } else if (this.$route.query.utm_content === "1") {
      this.title = `Чаепитие по-татарски в подарок! Дарим сет с самоваром и угощения при заказе от 1999 рублей. Промокод "Казань"`;
      (this.isVideo = false), (this.isImg1 = true);
    } else if (this.$route.query.utm_content === "2") {
      this.title = `Попробуйте национальную кухню татарского народа. Эчпочмак кейк в подарок при заказе от 999 рублей. Промокод "Вкусно"`;
      (this.isVideo = false), (this.isImg2 = true);
    } else if (this.$route.query.utm_content === "3") {
      this.title = `Попробуйте национальную кухню татарского народа. Десерт коймаки в подарок при заказе от 999 рублей. Промокод "Гость"`;
      (this.isVideo = false), (this.isImg3 = true);
    }
    window.innerWidth < 768
      ? (this.banner_img = "banner_m.png")
      : (this.banner_img = "banner.png");
  },
  methods: {
    setBlock() {
      localStorage.setItem("block", "banner");
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  width: 100%;
  height: 728px;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(42, 55, 75, 0.5);
    z-index: 1;
  }
  @media (max-width: $ms3) {
    height: 528px;
  }
  &__body {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1;
    padding: 0 20px;
    @media (max-width: $ms2) {
      justify-content: flex-end;
      padding: 0 20px 20px;
    }
    @media (max-width: $ms3) {
      justify-content: center;
      padding: 0 20px;
    }
  }
  &__title {
    margin-bottom: 50px;
    font-size: 48px;
    font-weight: 600;
    line-height: 64px;
    text-align: center;
    color: #fff;
    white-space: pre;
    @media (max-width: $ms3) {
      margin-bottom: 30px;
      font-size: 26px;
      line-height: 32px;
    }
  }
  &__subtitle,
  &__period {
    font-size: 24px;
    margin-top: 50px;
    white-space: pre;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: #fff;
    @media (max-width: $ms3) {
      font-size: 16px;
      margin-top: 30px;
      line-height: 24px;
      white-space: normal;
    }
  }
  &__buttons {
    display: flex;
    margin-top: 50px;
    @media (max-width: $ms3) {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 0;
    }
  }
  &__btn {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 72px;
    border-radius: 8px;
    padding: 0 30px;
    border: 1px solid #fff;
    background: none;
    font-family: "Montserrat";
    &:hover,
    &:focus {
      background: #fff;
      color: #000;
      transition: 0.2s ease 0.2s;
      img {
        filter: invert(1);
        transition: 0.2s ease 0.2s;
      }
    }
    img {
      margin-right: 1rem;
    }
    @media (max-width: $ms3) {
      font-size: 16px;
      line-height: 28px;
      height: 56px;
      padding: 0 20px;
      margin-top: 56px;
      min-width: 248px;
      img {
        width: 20px;
      }
    }
  }
  &__movie {
    height: 100%;
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      filter: brightness(0.5);
    }
    img {
      filter: brightness(0.4);
    }
  }
}
</style>
