<template>
  <div class="reviews" id="reviews">
    <div class="reviews__container">
      <h2 class="title">{{ title }}</h2>
      <div class="reviews__flex-col">
        <div class="reviews__body">
          <div class="_container">
            <div class="reviews__maps">
              <div class="reviews__map">
                <a
                  href="https://yandex.ru/maps/org/tatar_by_tubetey/6972396475/?ll=49.128480%2C55.789639&mode=search&sll=49.106414%2C55.796129&sspn=0.328217%2C0.130549&text=ресторан%20татар&z=14"
                  class="reviews__logo"
                >
                  <img
                    src="@/assets/img/reviews/maps/ya_maps.png"
                    alt="yandex"
                    width="152"
                  />
                </a>
                <div class="reviews__rank">
                  <div class="reviews__total">5,0</div>
                  <div class="reviews__counts">5384 оценки</div>
                </div>
              </div>
              <div class="reviews__map">
                <a
                  href="https://2gis.ru/kazan/search/татар/firm/70000001053910604/49.106849%2C55.798357"
                  class="reviews__logo"
                >
                  <img
                    src="@/assets/img/reviews/maps/logo_2gis.png"
                    alt="2gis"
                    width="134"
                  />
                </a>
                <div class="reviews__rank">
                  <div class="reviews__total">4,8</div>
                  <div class="reviews__counts">9541 оценка</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="reviews__slider">
          <swiper
            :modules="modules"
            :slides-per-view="4"
            :space-between="20"
            navigation
            :pagination="{ clickable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :loop="true"
            :breakpoints="{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }"
          >
            <swiper-slide v-for="image in images" :key="image.id"
              ><img
                :src="require(`@/assets/img/reviews/${image.img}`)"
                :alt="image.img"
            /></swiper-slide>
          </swiper>
        </div>
      </div>
      <button class="btn-arrow" @click="$emit('openPopupFeedback')">
        <span>Оставить отзыв</span>
        <img src="@/assets/img/svg/arrow-right.svg" alt="arrow" />
      </button>
    </div>
  </div>
</template>

<script>
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Import Swiper styles
export default {
  name: "reviewsPage",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, A11y],
    };
  },
  data() {
    return {
      title: "Отзывы",
      images: [
        { id: 1, img: "1.png" },
        { id: 2, img: "2.png" },
        { id: 3, img: "3.png" },
        { id: 4, img: "4.png" },
        { id: 5, img: "5.png" },
        { id: 6, img: "6.png" },
        { id: 7, img: "7.png" },
        { id: 8, img: "8.png" },
        { id: 9, img: "9.png" },
        { id: 10, img: "10.png" },
      ],
    };
  },
};
</script>

<style lang="scss">
.reviews {
  background-color: #f6ece9;
  padding: 70px 0 !important;
  @media (max-width: $ms3) {
    padding: 50px 0 !important;
  }
  &__flex-col {
    display: flex;
    flex-direction: column;
  }
  &__maps {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    @media (max-width: $ms3) {
      flex-direction: column;
    }
  }

  &__map {
    display: flex;
    align-items: center;
    @media (max-width: $ms3) {
      margin-top: 20px;
      justify-content: space-between;
    }
  }

  &__logo {
    margin-right: 100px;
    @media (max-width: $ms3) {
      margin-right: 20px;
    }
  }

  &__rank {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: $ms3) {
      flex: 0 0 auto;
    }
  }

  &__total {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #2a374b;
  }

  &__counts {
    font-size: 14px;
    line-height: 24px;
    color: #000;
    margin-top: 5px;
  }
  .btn-booking {
    position: relative;
    top: 75px;
    margin: 0 auto;
    z-index: 1;
  }
  .swiper {
    .swiper-slide {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__body {
    text-align: center;
  }

  &__address {
    margin-top: 30px;
  }

  &__list {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    ul {
      li {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        color: #fff;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        img {
          margin-right: 10px;
        }
      }
    }
  }

  &__slider {
    margin-top: 50px;
    @media (max-width: $ms3) {
      margin-top: 20px;
      order: -1;
      padding: 0 18px;
    }
  }
  .btn-arrow {
    margin: 60px auto 0;
    font-weight: 600;
    @media (max-width: $ms3) {
      margin: 30px auto 0;
      span {
        padding-left: 10px;
      }
    }
  }
  .swiper {
    &::before,
    &::after {
      box-shadow: inset 200px 0px 200px 0px #f6ece9;
    }
    .swiper-wrapper {
      right: -90px;
      @media (max-width: $ms3) {
        right: 0;
      }
    }
    .swiper-pagination {
      bottom: -30px;
      .swiper-pagination-bullet {
        background: rgba(42, 55, 75, 0.8);
      }
      .swiper-pagination-bullet-active {
        background: #2a374b;
      }
    }
  }
}
</style>
