<template>
  <section class="location" id="location">
    <div class="_container">
      <div class="location__container">
        <h2 class="title">Как нас найти</h2>
        <div class="location__block">
          <div class="location__addresses">
            <div class="location__address">
              <h3 class="title">Кремль, 5</h3>
              <div class="location__body">
                <a href="tel:+79274986400" class="location__phone"
                  ><img
                    src="@/assets/img/svg/phone.svg"
                    alt="phone"
                    width="16"
                  />+7 (927) 498-64-00</a
                >
                <div class="location__time">
                  <img src="@/assets/img/svg/time.svg" alt="time" width="16" />
                  <ul>
                    <li>Ежедневно 10:00 - 22:00</li>
                  </ul>
                </div>
              </div>
              <a
                href="https://yandex.ru/maps/org/tatar_by_tubetey/6972396475/?ll=49.106834%2C55.798154&mode=search&sctx=ZAAAAAgBEAAaKAoSCbfVrDO%2BjUhAEfn3GRcO5ktAEhIJ6Zyf4jjwqj8RxEMYP417kz8iBgABAgMEBSgKOABAiJ0GSAFqAnJ1nQHNzEw9oAEAqAEAvQFAOXi3wgEFu6fZ%2FBmCAhBUYXRhciBieSBUdWJldGV5igIAkgIAmgIMZGVza3RvcC1tYXBz&sll=49.106834%2C55.798154&sspn=0.014394%2C0.005205&text=Tatar%20by%20Tubetey&z=16.87"
                target="_blank"
                class="btn-arrow"
              >
                <span>Проложить маршрут</span>
                <img src="@/assets/img/svg/arrow-right.svg" alt="arrow" />
              </a>
            </div>
            <div class="location__arrow">
              <img
                src="@/assets/img/svg/vertical-arrow.svg"
                alt="vertical-arrow"
              />
            </div>
            <div class="location__address">
              <h3 class="title">Марджани, 4</h3>
              <div class="location__body">
                <a href="tel:+79370005181" class="location__phone"
                  ><img
                    src="@/assets/img/svg/phone.svg"
                    alt="phone"
                    width="16"
                  />+7 (937) 000-51-81</a
                >
                <div class="location__time">
                  <img src="@/assets/img/svg/time.svg" alt="time" width="16" />
                  <ul>
                    <li>ПН-ЧТ 10:00 - 22:00</li>
                    <li>ПТ,СБ 10:00 - 23:00</li>
                    <li>ВС 10:00 - 22:00</li>
                  </ul>
                </div>
              </div>
              <a
                href="https://yandex.ru/maps/org/tatar_by_tubetey/83948926057/?ll=49.116484%2C55.781388&mode=search&sctx=ZAAAAAgBEAAaKAoSCbfVrDO%2BjUhAEfn3GRcO5ktAEhIJ6Zyf4jjwqj8RxEMYP417kz8iBgABAgMEBSgKOABAiJ0GSAFqAnJ1nQHNzEw9oAEAqAEAvQFAOXi3wgEFu6fZ%2FBmCAhBUYXRhciBieSBUdWJldGV5igIAkgIAmgIMZGVza3RvcC1tYXBz&sll=49.116484%2C55.781388&sspn=0.013337%2C0.004825&text=Tatar%20by%20Tubetey&z=16.98"
                target="_blank"
                class="btn-arrow"
              >
                <span>Проложить маршрут</span>
                <img src="@/assets/img/svg/arrow-right.svg" alt="arrow" />
              </a>
            </div>
          </div>
          <div class="location__map">
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3Aaf3eb4b29edb60f151b41f82b259134b77e31fe3ef2c69692be5dffc42e5149b&amp;source=constructor"
              width="100%"
              height="534"
              frameborder="0"
              class="md"
            ></iframe>
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3Aaf3eb4b29edb60f151b41f82b259134b77e31fe3ef2c69692be5dffc42e5149b&amp;source=constructor"
              width="100%"
              height="500"
              frameborder="0"
              class="mm"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LocationMain",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss">
.location {
  background: #2a374b;
  padding: 70px 0;
  @media (max-width: $ms3) {
    padding: 50px 0;
    margin-bottom: -1px;
  }
  &__container {
    .title {
      color: #fff;
    }
  }

  &__block {
    width: 100%;
    max-width: 1104px;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
    margin: 40px auto 0;
    @media (max-width: $ms3) {
      margin: 20px auto 0;
    }
  }

  &__addresses {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $ms3) {
      flex-direction: column;
      padding: 30px 30px 40px;
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 80px 60px;
    button {
      margin-top: 20px;
    }
    @media (max-width: $ms2) {
      padding: 25px 20px 30px;
    }
    @media (max-width: $ms3) {
      padding: 0;
      align-items: flex-start;
    }
    .title {
      color: #2a374b;
      @media (max-width: $ms3) {
        margin-bottom: 30px;
      }
    }
  }

  &__phone,
  &__time {
    font-size: 18px;
    line-height: 28px;
    color: #5a5a5a;
    display: flex;
    margin-top: 20px;
    img {
      margin-right: 10px;
    }
    @media (max-width: $ms3) {
      margin-top: 0;
    }
  }

  &__phone {
    align-items: center;
  }

  &__time {
    align-items: flex-start;
    height: 56px;
    @media (max-width: $ms3) {
      height: auto;
    }
    img {
      margin-top: 5px;
    }
  }

  &__body {
    flex: 1 1 auto;
  }
  &__arrow {
    @media (max-width: $ms3) {
      transform: rotate(90deg);
    }
  }
  .btn-arrow {
    margin-top: 50px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    @media (max-width: $ms3) {
      font-size: 14px;
      display: flex;
      justify-content: center;
      span {
        flex: 0 0 auto;
      }
      img {
        width: 8px;
      }
    }
  }
  @media (max-width: $ms3) {
    &__map {
      height: 500px;
    }
  }
}
</style>
