<template>
  <div class="discounts" id="disc">
    <div class="_container">
      <div class="discounts__container">
        <h2 class="title">Акции и новости</h2>
        <div class="dishes">
          <div
            class="dish"
            v-for="discount in discounts"
            :key="discount.id"
            :id="discount.id"
            @click="token ? showPopupId(discount.id) : $emit('popupLogin')"
          >
            <div class="dish__view">
              <div class="dish__img">
                <img
                  :src="require(`@/assets/img/discounts/${discount.img}`)"
                  :alt="discount.title"
                />
              </div>
            </div>
            <div class="dish__body">
              <h4 class="dish__title">{{ discount.title }}</h4>
              <div class="dish__flex">
                <div class="dish__text">
                  {{ discount.text }}
                </div>
                <div class="dish__bottom">
                  <button
                    class="dish__more"
                    @click="
                      token ? showPopupId(discount.id) : $emit('popupLogin')
                    "
                  >
                    Подробнее
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <popup-mask-disc
    :class="popupMore ? '_show' : ''"
    @close="popupMore = false"
    v-for="discount in discounts"
    :key="discount.id"
    :popupId="popupId"
    :discount="discount"
  />
</template>

<script>
import PopupMaskDisc from "@/components/popups/PopupMaskDisc.vue";
export default {
  name: "DiscountsMain",
  components: { PopupMaskDisc },
  props: ["token"],
  data() {
    return {
      popupMore: false,
      popupId: 0,
      discounts: [
        {
          id: 1,
          title: "Кэшбэк 5%",
          text: "Оформи бонусную карту и получай кэшбэк 5% за каждый заказ",
          img: "1.png",
        },
      ],
    };
  },
  methods: {
    showPopupId(popupId) {
      console.log(popupId);
      this.popupId = popupId;
      this.popupMore = true;
    },
  },
};
</script>

<style lang="scss">
.discounts {
  background-color: #fafafa;
  padding: 50px 0;
  .dishes {
    display: flex;
    justify-content: center;
    @media (max-width: $ms3) {
      flex-direction: column;
    }
  }
  .dish {
    margin-top: 35px;
    max-width: 432px;
    @media (max-width: $ms3) {
      margin-top: 20px;
      max-width: unset;
      &__title {
        font-size: 18px;
      }
    }
    &__flex {
      display: flex;
    }
    &__img {
      height: 254.77px;
      @media (max-width: $ms3) {
        height: 184px;
      }
    }

    &__text {
      margin-bottom: 0;
      padding-right: 20px;
      @media (max-width: $ms3) {
        font-size: 12px;
      }
    }

    &__bottom {
      display: flex;
    }
    &__more {
      font-family: "Montserrat", sans-serif;
      @media (max-width: $ms4) {
        font-size: 8px;
        line-height: 14px;
        text-decoration: underline;
      }
    }
    &__body {
      padding: 20px 30px 30px;
      @media (max-width: $ms4) {
        padding: 10px 10px 20px;
      }
    }
  }
}
</style>
